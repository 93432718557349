html {
  /* added to prevent "Skip to main content" link focus behavior from scrolling the main content behind the sticky app header/nav.  */
  scroll-padding-top: 10rem;
}

body {
  margin: 0;
  font-family: Knockout, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-feature-settings: "ss02";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* UsableNet "Enable Accessibility" button positioning adjustment (mobile-only; < md breakpoint) */
@media (width < 768px) {
  #usntA40Toggle {
    bottom: 73px;
  }
}

/* Ketch font-family override (https://docs.ketch.com/ketch/docs/custom-style-overrides#custom-font) */
#lanyard_root * {
  font-family: Knockout, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-feature-settings: "ss02";
  font-weight: 325 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
